import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/Home";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import ContactPage from "./pages/Contact";
import CarbonCommitmentPage from "./pages/carbonhub";
import TermsPage from "./pages/terms";
import PrivacyPolicyPage from "./pages/Privacypolicy";
function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
      <Route path="/" element={<HomePage/>} />
	     <Route path="/carbonhub" element={<CarbonCommitmentPage />} />
        <Route path="/contact" element={<ContactPage/>} />
        <Route path="/terms" element={<TermsPage/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
