import Workimg from '../images/Work.svg'
import { Link } from 'react-router-dom'
export default function ContactPage() {
    return (
        <div className="container mx-auto px-4">
            <div className="flex items-center w-full justify-center">
                <div className="contact-details sm:w-full  md:w-full lg:w-1/2 flex flex-col">
                    <h6 className='font-bold text-3xl text-black pb-4 lg:text-left sm:text-center'>Get in touch</h6>
                    <p>We're here to help! Whether you have questions about our products, need assistance, or simply want to share your thoughts, we'd love to hear from you.</p>
                    <p className='py-2'>Unlock the power of your data with our expert data consultation services. Whether you're exploring ways to optimize your data strategy, facing challenges in data management, or seeking guidance on analytics and insights, our team of data specialists is ready to assist you.</p>
                    <Link className='my-3 rounded flex text-center justify-center items-center text-white h-[40px] bg-button-blue py-6 border-2 w-[200px] border-button-blue' to="https://calendly.com/christophernyaga411/consulting-first-call">Schedule Free consultation Now!</Link>
                </div>
                <div className="contact-intro-img flex lg:w-1/2 lg:block md:hidden xl:block sm:hidden">
                    <img src={Workimg} className='object-contain' alt="contact" />
                </div>
            </div>
            <div className='flex container'>
                <div className='contact-form flex-col flex w-[800px] mx-auto justify-center'>
                    <div className='details flex w-full gap-3'>
                        <div className='form-label flex flex-col w-1/2'>
                            <label>Your Name</label>
                            <input type='text' className='w-full mt-2 mb-2 block  h-[50px] px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400' placeholder='Your Name' />
                        </div>
                        <div className='form-label flex flex-col w-[50%]'>
                            <label>Email</label>
                            <input type='text' className='w-full mt-2 mb-2 block  h-[50px] px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400' placeholder='Email' />
                        </div>
                    </div>
                    <div className='message flex w-[100%]'>
                        <textarea rows="4" className='w-full block mt-2 mb-2 border  border-slate-400 px-3 py-2 rounded-md bg-white text-sm shadow-sm placeholder-slate-400' placeholder='Message' cols="100" />
                    </div>
                    <div className='submit-btn flex justify-end'>
                        <button className='bg-button-green text-white rounded-sm w-[142px] normal-case h-[40px]'>Send</button>
                    </div>
                </div>
            </div>
        </div>
    )
}