import HeroSection from "../components/hero/hero";
import Services from "../components/services/services";
import { BsFillAwardFill } from "react-icons/bs";
import { CgInsights } from "react-icons/cg";
import { PiLightbulbFilamentThin } from "react-icons/pi";
import { GrStatusGood } from "react-icons/gr";
import { FaHandshake } from "react-icons/fa";
import { RiGovernmentLine } from "react-icons/ri";
import ServiceImg from '../images/bg.png'

import ExpertImg from '../images/expert.png';
import MarketImg from '../images/market.png';
import SolutioniMg from '../images/solution.png';
import HandShake from '../images/handshake.png';
import ResultImg from '../images/result.png';
import InnovatesImg from '../images/innovates.png';
import CustomerC from '../images/customer.png';

export default function HomePage() {
    return (
        <div className="w-full">
            <div className="hero-begins w-full">
                <HeroSection />

            </div>

            <div className="our-what-we-do">
            <div className="container mx-auto my-0 p-8 rounded-lg shadow-md">
                <p className="text-black font-bold text-center tracking-wide">At DataLyteGreen, we empower your business with expertise in data, AI, and climate solutions.</p>
                <p className="text-black font-bold text-center tracking-wide">Join us in shaping a smart, sustainable future together.</p>
                </div>
                
                <div className="container mx-auto">
                    <div className='flex flex-col lg:justify-normal sm:flex-row sm:items-center sm:justify-center mx-auto'>

                        <div className='flex flex-col  xl:w-1/2 lg:w-1/2 sm:p-8'>

                            <div className='w-full'>

                                <div className="our-what-we-do-intro">

                                    <p className="font-[300] text-[18px] py-3">Explore the future of your business where your data takes center stage.</p>
                                </div> 
                                <div className="our-what-we-do-details w-full flex flex-col">
                                    <div className="our-what-we-do-details-card flex gap-8 py-3  w-full">
                                        <div className="our-what-we-do-details-card-img">
                                            <BsFillAwardFill className="text-button-green text-[18px] mt-1"/>
                                        </div> 
                                        <div className="our-what-we-do-details-card-info flex items-center">
                                            <p className=""> <span className="text-black text-[18px] font-bold">Data Literacy</span> <span className="text-sm text-gray-500">We empower your team with the knowledge to unleash the full potential of your data.</span></p>
                                        </div>
                                    </div>
                                    <div className="our-what-we-do-details-card flex gap-8 py-3  w-full">
                                        <div className="our-what-we-do-details-card-img">
                                            <CgInsights className="text-button-green text-[18px] mt-1"/>
                                        </div>
                                        <div className="our-what-we-do-details-card-info flex items-center">
                                            <p> <span className="text-black text-[18px] font-bold">Data Lineage</span> <span  className="text-sm text-gray-500">We help,advise,reveal the intricate journey of your data, providing insights that shape decisions.</span></p>
                                        </div>
                                    </div>
                                    <div className="our-what-we-do-details-card flex gap-8 py-3  w-full">
                                        <div className="our-what-we-do-details-card-img">
                                            <PiLightbulbFilamentThin  className="text-button-green text-[18px] mt-1"/>
                                        </div>
                                        <div className="our-what-we-do-details-card-info flex items-center">
                                            <p> <span className="text-black text-[18px] font-bold">Data Strategy</span> <span className="text-sm text-gray-500">We help in crafting bespoke data strategies aligned with your unique business objectives.</span></p>
                                        </div>
                                    </div>
                                    <div className="our-what-we-do-details-card flex gap-8 py-3  w-full">
                                        <div className="our-what-we-do-details-card-img">
                                            <GrStatusGood className="text-button-green text-[18px] mt-1"/>
                                        </div>
                                        <div className="our-what-we-do-details-card-info flex items-center">
                                            <p> <span className="text-black text-[18px] font-bold">Data Quality</span>  <span className="text-sm text-gray-500">Improve your data's dependability and accuracy with thorough quality assurance/checks.</span></p>
                                        </div>
                                    </div>
                                    <div className="our-what-we-do-details-card flex gap-8 py-3  w-full">
                                        <div className="our-what-we-do-details-card-img">
                                            <RiGovernmentLine className="text-button-green text-[18px] mt-1"/>
                                        </div>
                                        <div className="our-what-we-do-details-card-info flex items-center">
                                            <p>  <span className="text-black text-[18px] font-bold">Data Governance</span> <span className="text-sm text-gray-500">Let's instill confidence in your data management through effective governance frameworks.</span></p>
                                        </div>
                                    </div>
                                    <div className="our-what-we-do-details-card flex gap-8 py-3  w-full">
                                        <div className="our-what-we-do-details-card-img">
                                            <FaHandshake className="text-button-green text-[18px] mt-1"/>
                                        </div>
                                        <div className="our-what-we-do-details-card-info flex items-center">
                                            <p> <span className="text-black text-[18px] font-bold">Data Contract</span> <span className="text-sm text-gray-500">Simplify data agreements and contracts for seamless collaborations.</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-col  xl:w-1/2 lg:w-1/2 sm:p-8'>

                            <div className="w-full">
                                <img src={ServiceImg} alt='stats'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            

            <div className="services">
                <Services />
            </div>
            <div className='why-choose-our-services bg-[#fafafa]'>
                <div className="about-us-header flex flex-col items-center justify-center">
                    <h6 className="text-black text-2xl text-inherit font-bold py-2">Why Consult with Us</h6>
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-3 md:grid-cols-3 mx-4 xl:grid-cols-3'>
                    <div className='value-card  flex items-center flex-col bg-white justify-center  sm:w-full'>
                        <div className='value-icon w-[70px] h-[70px]'>
                            <img src={ExpertImg} className='w-[100%] h-[100%]' alt='innovation' />
                        </div>
                        <div className='value-details flex flex-col items-center justify-center text-center'>
                            <h6 className='font-semibold text-2xl py-3'>Expert Guidance</h6>
                            <p> Benefit from the expertise of seasoned professionals in the field, providing unparalleled insights and solutions.</p>
                        </div>
                    </div>
                    <div className='value-card flex items-center flex-col bg-white py-3 justify-center sm:w-full'>
                        <div className='value-icon w-[70px] h-[70px]'>
                            <img src={MarketImg} className='w-[100%] h-[100%]' alt='innovation' />
                        </div>
                        <div className='value-details flex flex-col items-center justify-center text-center'>
                            <h6 className='font-semibold text-2xl py-3'>Tailored Approach</h6>
                            <p> We understand that each business is unique. Our services are customized to address your specific challenges and goals.</p>
                        </div>
                    </div>
                    <div className='value-card flex items-center flex-col bg-white py-3 justify-center sm:w-full'>
                        <div className='value-icon w-[70px] h-[70px]'>
                            <img src={SolutioniMg} className='w-[100%] h-[100%]' alt='innovation' />
                        </div>
                        <div className='value-details flex flex-col items-center justify-center text-center'>
                            <h6 className='font-semibold text-2xl py-3'>Comprehensive Solutions</h6>
                            <p> From data literacy to cloud migration, we offer an end-to-end suite of services, ensuring all aspects of your data needs are covered.</p>
                        </div>
                    </div>
                    <div className='value-card flex items-center flex-col bg-white py-3 justify-center sm:w-full'>
                        <div className='value-icon w-[70px] h-[70px]'>
                            <img src={HandShake} className='w-[100%] h-[100%]' alt='innovation' />
                        </div>
                        <div className='value-details flex flex-col items-center justify-center text-center'>
                            <h6 className='font-semibold text-2xl py-3'>Strategic Partnerships</h6>
                            <p> Forge a collaborative partnership with us to navigate the complexities of data, enhancing your capabilities and staying ahead in the competitive landscape.</p>
                        </div>
                    </div>
                    <div className='value-card flex items-center flex-col bg-white py-3 justify-center sm:w-full'>
                        <div className='value-icon w-[70px] h-[70px]'>
                            <img src={ResultImg} className='w-[100%] h-[100%]' alt='innovation' />
                        </div>
                        <div className='value-details flex flex-col items-center justify-center text-center'>
                            <h6 className='font-semibold text-2xl py-3'>Proven Results</h6>
                            <p> Our track record speaks for itself. Experience tangible improvements in efficiency, data quality, and overall business performance.</p>
                        </div>
                    </div>
                    <div className='value-card flex items-center flex-col bg-white py-3 justify-center sm:w-full'>
                        <div className='value-icon w-[70px] h-[70px]'>
                            <img src={InnovatesImg} className='w-[100%] h-[100%]' alt='innovation' />
                        </div>
                        <div className='value-details flex flex-col items-center justify-center text-center'>
                            <h6 className='font-semibold text-2xl py-3'>Innovation at the Core</h6>
                            <p> We embrace cutting-edge technologies and industry best practices, ensuring your business stays at the forefront of innovation.</p>
                        </div>
                    </div>
                </div>
                <div className='value-card flex  flex-col py-3  sm:w-full'>
                    <div className='w-[400px] mx-auto bg-white px-5'>
                        <div className='w-full flex flex-col items-center justify-center'>
                            <div className='value-icon w-[70px] h-[70px]'>
                                <img src={CustomerC} className='w-[100%] h-[100%]' alt='innovation' />
                            </div>
                            <div className='value-details flex flex-col items-center justify-center text-center'>
                                <h6 className='font-semibold text-2xl py-3'>Customer-Centric Approach</h6>
                                <p> Your satisfaction is our priority. Our customer-centric approach ensures a seamless and positive experience throughout our engagement.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="partners">

            </div>
        </div>
        
    )
}