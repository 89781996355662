import React from 'react';
import './CarbonCommitmentPage.css'; 
import infographic1 from '../images/carbons.png'
import infographic2 from '../images/Environ.png'


function CarbonCommitmentPage() {
  return (
    <div className="carbon-commitment-page">
      <div className="container">
        <h1 className="title">Carbon Solutions Hub</h1>
        <h3 className="subtitle">Our Commitment to Net-Zero Carbon by 2050</h3>
        <p className="paragraph">
          At DataLyteGreen, we are dedicated to playing our part in combating climate change and reducing our carbon footprint. We recognize the urgent need to address the environmental challenges facing our planet, and we are committed to help develop solutions in achieving net-zero carbon emissions by 2030.
        </p>
        <p className="paragraph">
          Achieving net-zero carbon emissions requires a comprehensive approach that involves understanding and managing various aspects of carbon, from emissions to sequestration and trading. Below, we outline key terms related to carbon management and our efforts towards sustainability.
        </p>
        <div className="visual-insights-section">
          <h2 className="section-title">Carbon Cycle</h2>
          <div className="infographics-container">
            {/* Infographic 1 */}
            <img src={infographic1} alt="Infographic 1" className="infographic" />
            {/* Infographic 2 */}
            <img src={infographic2} alt="Infographic 2" className="infographic" />
          </div>
        </div>

        <h2 className="section-title">Key Terms in Carbon Management</h2>

        {/* Terms */}
        <div className="term-container">
          <div className="term">
            <h3>Carbon Sink</h3>
            <p>A reservoir that absorbs more carbon from the atmosphere than it releases, such as forests, oceans, and soil.</p>
          </div>

          <div className="term">
            <h3>Carbon Sequestration</h3>
            <p>The process of capturing and storing carbon dioxide from the atmosphere to mitigate climate change. We invest in development of  technologies and advisor practices that promote carbon sequestration, such as reforestation and carbon capture and storage.</p>
          </div>

          <div className="term">
            <h3>Carbon Trading</h3>
            <p>A market-based approach to reducing greenhouse gas emissions, where companies or countries can buy and sell permits or credits that represent the right to emit a certain amount of carbon dioxide or other greenhouse gases.</p>
          </div>

          <div className="term">
            <h3>Carbon Emission</h3>
            <p>The release of carbon dioxide (CO2) and other greenhouse gases into the atmosphere, primarily as a result of human activities such as burning fossil fuels, deforestation, and industrial processes.</p>
          </div>

          <div className="term">
            <h3>Carbon Consumption</h3>
            <p>The total amount of carbon dioxide emissions produced by consuming goods and services, taking into account the entire life cycle of a product or activity, including production, transportation, and disposal.</p>
          </div>

          <div className="term">
            <h3>Carbon Quantification</h3>
            <p>The process of measuring and assessing the amount of carbon dioxide emitted or sequestered by a particular activity, process, or ecosystem, often expressed in terms of carbon dioxide equivalents (CO2e).</p>
          </div>
          <div className="term">
            <h3>Carbon Neutrality</h3>
            <p>Achieving a balance between emitting carbon and absorbing carbon from the atmosphere, resulting in a net zero carbon footprint. This can be accomplished through a combination of reducing emissions and investing in projects that remove carbon dioxide from the atmosphere, such as reforestation.</p>
          </div>

          <div className="term">
            <h3>Carbon Pricing</h3>
            <p>The practice of setting a price on carbon emissions, either through taxes, cap-and-trade systems, or other mechanisms, to incentivize reductions in greenhouse gas emissions. By putting a monetary value on carbon emissions, carbon pricing encourages businesses and individuals to adopt cleaner technologies and practices.</p>
          </div>

          <div className="term">
            <h3>Carbon Leakage</h3>
            <p>The phenomenon where efforts to reduce emissions in one country or region result in increased emissions in another due to shifts in production or consumption patterns. This can occur when industries move their operations to countries with less stringent emission regulations, leading to a global increase in emissions despite local reductions.</p>
          </div>

          <div className="term">
            <h3>Carbon Intensity</h3>
            <p>The amount of carbon (usually measured in CO2 emissions) emitted per unit of activity, such as per unit of energy produced or per unit of GDP. Carbon intensity is often used as a measure of the environmental impact of economic activities and can be reduced through improvements in efficiency and the adoption of cleaner technologies.</p>
          </div>
          <div className="term">
            <h3>Biomass</h3>
            <p>Organic matter derived from plants or animals, often used as a renewable energy source through processes like burning for heat or electricity generation, or conversion into biofuels such as ethanol or biodiesel.</p>
          </div>

          <div className="term">
            <h3>Carbon Footprint</h3>
            <p>The total amount of greenhouse gases (including carbon dioxide) emitted directly or indirectly by human activities, typically expressed in equivalent tons of carbon dioxide emitted per year.</p>
          </div>

          <div className="term">
            <h3>Carbon Offset</h3>
            <p>The reduction in emissions of carbon dioxide or other greenhouse gases made to compensate for emissions made elsewhere, often achieved through projects that remove or prevent the release of greenhouse gases, such as reforestation or renewable energy projects.</p>
          </div>

          <div className="term">
            <h3>Carbon Capture and Storage (CCS)</h3>
            <p>The process of capturing waste carbon dioxide from large point sources, such as power plants or industrial facilities, transporting it to a storage site, and storing it underground or in geological formations to prevent it from entering the atmosphere.</p>
          </div>
          <div className="term">
            <h3>Carbon Cycle</h3>
            <p>The natural process by which carbon moves between the atmosphere, oceans, soil, and living organisms, playing a crucial role in regulating Earth's climate. The carbon cycle includes processes such as photosynthesis, respiration, and the decomposition of organic matter, all of which contribute to the exchange of carbon between different reservoirs.</p>
          </div>

          <div className="term">
            <h3>Carbon Negative</h3>
            <p>Describes activities or technologies that result in the removal of more carbon dioxide from the atmosphere than they emit, thus reducing the overall concentration of CO2. Examples of carbon negative practices include reforestation, carbon capture and storage, and bioenergy with carbon capture and storage (BECCS).</p>
          </div>

          <div className="term">
            <h3>Carbon Farming</h3>
            <p>Agricultural practices that aim to sequester carbon in soils and vegetation to mitigate climate change and improve soil health. Carbon farming techniques include cover cropping, conservation tillage, rotational grazing, and agroforestry, all of which increase the amount of carbon stored in agricultural ecosystems.</p>
          </div>

          <div className="term">
            <h3>Carbon Credit</h3>
            <p>A tradable permit or certificate representing the right to emit one ton of carbon dioxide or equivalent greenhouse gas, often used in cap-and-trade systems. Carbon credits can be bought and sold on carbon markets, allowing businesses to offset their emissions by investing in emission reduction projects elsewhere.</p>
          </div>

          <div className="term">
            <h3>Carbon Registry</h3>
            <p>A database or system for tracking and verifying carbon emissions reductions, offsets, and carbon credits. Carbon registries play a crucial role in ensuring the integrity and transparency of carbon markets by providing a centralized platform for recording and reporting emission reduction activities.</p>
          </div>

          <div className="term">
            <h3>Carbon Disclosure</h3>
            <p>The practice of reporting and disclosing information about a company's carbon emissions, strategies for emissions reductions, and climate-related risks and opportunities. Carbon disclosure allows investors, consumers, and other stakeholders to assess a company's environmental performance and sustainability efforts.</p>
          </div>

          <div className="term">
            <h3>Carbon Tax</h3>
            <p>A tax levied on the carbon content of fossil fuels, intended to internalize the external costs of carbon emissions and incentivize cleaner energy sources.</p>
          </div>
        </div>
        <div className="solution-section">
          <h2 className="section-title">EO  and In-situ Data</h2>
          <p className="paragraph">
            At DataLyteGreen, we leverage cutting-edge technologies and use Earth observation  data and in-situ to develop innovative solutions for carbon management and environmental monitoring. By combining satellite imagery, remote sensing data, and ground-based measurements, we gain valuable insights into carbon dynamics and ecosystem health.
          </p>
          <p className="paragraph">
            Our team of experts utilizes advanced analytics and modeling techniques to analyze large volumes of geospatial data and extract actionable information for our clients.
          </p>
          <p className="paragraph">
            Whether it's assessing the impact of land management practices on carbon sequestration or mapping urban heat islands to improve urban planning, our solutions empower organizations to make informed decisions and drive positive environmental outcomes.
          </p>
          <p className="paragraph">
            By harnessing the power of Earth observation and in-situ , we are committed to advancing sustainability and creating a greener future for generations to come.
          </p>
          
        </div>
        <iframe
        src="https://climateprojects.projects.earthengine.app/view/worldenvironmentday2024"
        style={{
          width: '100%',
          height: '600px',
          border: 'none',
        }}
        title="World Environment Day 2024"
      ></iframe>
      </div>
    </div>
  );
}

export default CarbonCommitmentPage;
