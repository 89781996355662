import DashImg from '../../images/dashboard.png';
import DeImg from '../../images/de.png';
import AiImg from '../../images/ai.png';
import AwsImg from '../../images/aws.png';

export default function Services() {
    return (
        <div className="what-we-do pt-3">
            <div className="service-header flex flex-col items-center text-center justify-center px-5">
                <h6 className="text-black text-3xl uppercase font-bold py-2">What we do</h6>
                <p className="font-bold tracking-wide">We Design, Build, Automate & Maintain Data Solutions</p>

            </div>

            <div className="service-cards container mx-auto pt-5">
                <div className="grid py-6 grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 md:grid-cols-2 bg-[#D9D9D9]">
                    <div className="service-card flex items-center px-9 justify-center sm:w-full">
                        <div className='w-full flex flex-col items-center justify-center gap-2'>
                            <div className="service-card-img w-[200px] h-[200px]">
                                <img src={DashImg} className='object-contain h-[100%] w-[100%]' alt="bsintelligence" />
                            </div>
                            <div className="service-card-content text-center px-9">
                                <h6 className='text-black leading-3 font-bold tracking-wide pb-3'>Business Intelligence Solutions</h6>
                                <p>We Strategically design, architect, re-architect, and meticulously develop, automate BI solutions to empower your data-driven decision-making.</p>
                            </div>
                        </div>
                    </div>
                    <div className="service-card flex items-center px-9 justify-center">
                        <div className='w-full flex flex-col items-center justify-center gap-2'>
                            <div className="service-card-img w-[200px] h-[200px]">
                                <img src={DeImg} className='object-contain  h-[100%] w-[100%]' alt="bsintelligence" />
                            </div>
                            <div className="service-card-content text-center">
                                <h6 className='text-black leading-3 font-bold tracking-wide pb-3'>Data Engineering</h6>
                                <p>
                                    We design and manage robust ETL/ELT workflows, extracting valuable insights from diverse sources and seamlessly integrating them into data platforms.
                                    Our commitment is to deliver data engineering solutions that align seamlessly with your business demands, ensuring efficiency, scalability, and security.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="service-card flex items-center px-9 justify-center">
                        <div className='w-full flex flex-col items-center justify-center gap-2'>
                            <div className="service-card-img w-[200px] h-[200px]">
                                <img src={AiImg} className='object-contain  h-[100%] w-[100%]' alt="bsintelligence" />
                            </div>
                            <div className="service-card-content text-center">
                                <h6 className='text-black leading-3 font-bold tracking-wide pb-3'>Geospatial AI & Data Science Solutions</h6>
                                <p>We develop climate-smart solutions utilizing Google Earth Engine (GEE) and Python. We delve into the realm of Machine Learning to craft innovative data-driven solutions.</p>
                            </div>
                        </div>
                    </div>
                    <div className="service-card flex items-center justify-center">
                        <div className='w-full flex flex-col items-center justify-center gap-2'>
                            <div className="service-card-img w-[200px] h-[200px]">
                                <img src={AwsImg} className='object-contain  h-[100%] w-[100%]' alt="bsintelligence" />
                            </div>
                            <div className="service-card-content text-center px-9">
                                <h6 className='text-black leading-3 font-bold tracking-wide pb-3'>Migration of Onprem Workloads to Cloud</h6>
                                <p>We ensure a seamless migration to AWS with our expert support. We specialize in crafting tailored plans for a smooth transition of applications, data, and Servers. Our AWS-certified professionals optimize performance, scalability, and cost-efficiency, ensuring your organization benefits from enhanced agility, security, and innovation. Trust our team to elevate your business operations in the dynamic digital space with the power of AWS cloud.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
