import terms from '../data/terms.json'
export default function TermsPage() {
    return (
        <div className="container mx-auto py-3">
            <div className="term-conditions flex flex-col justify-center content-center">
                <div className="terms-intro py-3">
                    <h6 className='font-bold leading-3 text-black text-[22px] py-3'>DataLyteGreen Terms and Conditions</h6>
                    <p>Last Updated  2024-01-01</p>
                </div>
                {terms?.map((term, index) => (
                    <div key={index} className="term-details flex flex-col py-3">
                        <div className='term-details-intro flex items-center'>
                            <span className='font-bold leading-3'>{index + 1}</span>
                            <h6 className='font-bold leading-3 text-black text-[22px] px-4'>{term?.termTitle}</h6>
                        </div>
                        <div className="term-details-info pt-3">
                            <p className='py-1 text-sm'>{term?.termDetail}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}