import './hero.css';
import video from './videobg.mp4';

export default function HeroSection() {
  return (
    <div className='heros_section w-full h-[650px] flex flex-col justify-center items-center'>
  
      <video src={video} className='w-full h-full object-cover' autoPlay loop muted />
   
      <div className='flex justify-center text-center flex-col pb-8 md:pb-16 lg:pb-20' style={{ background: 'black', margin: '0 auto', padding: '8px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
  <h2 className="font-bold text-button-green tracking-wide text-lg md:text-2xl lg:text-3xl">WE TRANSFORM DATA COMPLEXITY INTO SIMPLICITY.</h2>
  <p className="text-white font-bold tracking-wide text-xs md:text-1xl lg:text-2xl">Simplify your data challenges effortlessly with our expert solutions, making complexity a thing of the past.</p>

  <h6 className='text-button-green text-xs md:text-base lg:text-lg'>
    Data, Automation & Strategy.
  </h6>
  <h5 className='text-button-green pt-1 md:pt-2 lg:pt-3 text-xs md:text-base lg:text-lg'>
    Reinvent Your Organization & Maximize Your Value.
  </h5>
</div>

      
    </div>
  );
}
