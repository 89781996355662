export default function PrivacyPolicyPage() {
    return (
        <div className="privacy-policy-page">
            <div className="container mx-auto py-3">
                <div className="term-conditions flex flex-col justify-center content-center">
                    <div className="terms-intro py-3">
                        <h6 className='font-bold leading-3 text-black text-[22px] py-3'>DataLyteGreen Privacy Policy</h6>
                        <p>Last Updated  2024-01-01</p>
                    </div>
                    <div className="privacy-intro">
                        <p>Welcome to DataLyteGreen! This Privacy Policy outlines how we collect, use, and protect your personal information. By using our services, you agree to the terms of this Privacy Policy.</p>
                    </div>
                    <div className="privacy-policy-item py-2">
                        <div className="privacy-policy-header">
                            <h6 className="font-bold text-black text-[22px]">Information We Collect</h6>
                        </div>
                        <div className="privacy-policy-details py-1">
                            <li>Contact information (Name, Email address, Company name, Job title)</li>
                            <li>Message content</li>
                        </div>
                    </div>
                    <div className="privacy-policy-item py-2">
                        <div className="privacy-policy-header">
                            <h6 className="font-bold text-black text-[22px]">Legal Basis for Processing</h6>
                        </div>
                        <div className="privacy-policy-details ">
                            <p className="py-1">We process your contact information based on your explicit consent when submitting the contact form, as required by the DPA 2019 and GDPR.</p>
                        </div>
                    </div>
                    <div className="privacy-policy-item py-2">
                        <div className="privacy-policy-header">
                            <h6 className="font-bold text-black text-[22px]">How We Use Your Information</h6>
                        </div>
                        <div className="privacy-policy-details ">
                            <p> We use the collected information to:</p>
                                <li>Respond to your inquiries.</li>
                                <li>Provide information about our services.</li>
                                <li> Communicate with you</li>
                        </div>
                    </div>
                    <div className="privacy-policy-item py-2">
                        <div className="privacy-policy-header">
                            <h6 className="font-bold text-black text-[22px]">Information Sharing</h6>
                        </div>
                        <div className="privacy-policy-details ">
                            <p>We do not sell, trade, or otherwise transfer your personal information to outside parties. We may share information with third-party service providers solely for the purpose of fulfilling your inquiry.</p>
                        </div>
                    </div>
                    <div className="privacy-policy-item py-2">
                        <div className="privacy-policy-header">
                            <h6 className="font-bold text-black text-[22px]">Data Retention</h6>
                        </div>
                        <div className="privacy-policy-details ">
                            <p>We retain the information you provide through the contact form for as long as necessary to fulfill the purpose for which it was collected or as required by law.</p>
                        </div>
                    </div>
                    <div className="privacy-policy-item py-2">
                        <div className="privacy-policy-header">
                            <h6 className="font-bold text-black text-[22px]">Security</h6>
                        </div>
                        <div className="privacy-policy-details ">
                            <p>We implement reasonable security measures to protect the information submitted through our contact form</p>
                        </div>
                    </div>
                    <div className="privacy-policy-item py-2">
                        <div className="privacy-policy-header">
                            <h6 className="font-bold text-black text-[22px]">Your Choices</h6>
                        </div>
                        <div className="privacy-policy-details ">
                            <p>You have the right to:</p>
                            <li>Access your personal information</li>
                            <li>Correct inaccuracies</li>
                            <li>Object to processing</li>
                            <li>Request deletion</li>
                        </div>
                    </div>
                    <div className="privacy-policy-item py-2">
                        <div className="privacy-policy-header">
                            <h6 className="font-bold text-black text-[22px]">Updates to the Privacy Policy</h6>
                        </div>
                        <div className="privacy-policy-details ">
                            <p>This Privacy Policy may be updated periodically. The last update was on 2024-01-01.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}