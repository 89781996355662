import LogoImg from '../../images/dlg.png'
import { Link } from 'react-router-dom'
import { MdEmail } from "react-icons/md";
import { FaTwitter, FaLinkedin, FaWhatsapp, FaMedium } from "react-icons/fa";
import { BiLogoDevTo } from "react-icons/bi";

export default function Footer() {
    return (
        <div className="footer-page w-full bg-[#3C3838] mt-2 text-white">
            <div className=" grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 md:grid-cols-2  items-center">
                <div className="footer-item flex flex-col">
                    <img src={LogoImg} className='' alt="logo" style={{ maxWidth: '70%', height: '50%' }}  />
                </div>
                <div className='footer-item flex flex-col sm:w-full'>
                    <div className='footer-item-header'>
                        <p className='text-[#00a8ff] font-bold'>Quick Links</p>
                    </div>
                    <Link to="/about" className='text-sm mt-2'> Projects & Services</Link>
                    <Link to="/about" className='text-sm mt-1'> About us</Link>
                    <Link to="/services" className='text-sm mt-1'> Services</Link>
                </div>
                <div className='footer-item flex flex-col'>
                    <div className='footer-item-header'>
                        <p className='text-[#00a8ff] font-bold'>Contact us</p>
                    </div>
                    <div className='footer-content mt-2'>
                        <div className='flex items-center'>
                            <MdEmail className='text-[#95a5a6]' />
                            <span className='px-2'>info@datalytegreen.com</span>
                        </div>
                        <div className='flex items-center'>
                            <FaWhatsapp className='text-[#4FFA13]' />
                            <span className='px-2'>+254776542149</span>
                        </div>

                    </div>
                </div>
                <div className='footer-item flex flex-col'>
                    <div className='footer-item-header'></div>
                    <div className='footer-content'>
                        <div>
                            <Link to='/privacy-policy' target='_blank' rel='noopener noreferrer'><span className='text-sm mt-1'>Privacy policy</span></Link>
                        </div>
                        <div>
                            <Link to='/terms' target='_blank' rel='noopener noreferrer'><span className='text-sm mt-1'>Terms & conditions</span></Link>
                        </div>
                        {/* <div>
                            <Link to='/awscommunitydayslides' target='_blank' rel='noopener noreferrer'><span className='text-sm mt-1'>AWS Community Day</span></Link>
                        </div> */}
                        <div className='flex items-start mt-1'>
                            <Link to='https://twitter.com/datalyte' className=''> <FaTwitter /></Link>
                            <Link to='/' className='px-1'> <FaMedium /></Link>
                            <Link to='https://www.linkedin.com/company/datalyte/' className='px-1'> <FaLinkedin /></Link>
                            <Link to='/' className='px-1'> <BiLogoDevTo /></Link>
                        </div>
                    </div>
                </div>
            </div>
            
            <hr className='text-white' />
            <div className='copyright-footer flex justify-center items-center py-2'>
                <p> Copyright &copy; {new Date().getFullYear()} DataLyteGreen. All Rights Reserved.</p>

            </div>
        </div>
    )
}
